import * as React from 'react';
import classNames from 'classnames';
import { SkinScreenWidthContainerProps } from '../../../SkinScreenWidthContainer';
import { useScrollPosition } from '../../../../../providers/useScrollPosition';
import skinsStyle from './styles/skins.scss';

const SCROLLED_Y = 2;
const AfterScroll: React.FC<SkinScreenWidthContainerProps> = ({
  wrapperProps: {
    id,
    tagName = 'div',
    eventHandlers,
    className: propsClassName,
  },
  children,
  animation,
}) => {
  const SemanticElement = tagName as keyof JSX.IntrinsicElements;
  const [scrolled, setScrolled] = React.useState<boolean>(false);

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y * -1 >= SCROLLED_Y) {
        if (!scrolled) {
          setScrolled(true);
        }
      } else if (scrolled) {
        setScrolled(false);
      }
    },
    [scrolled],
  );

  return (
    <SemanticElement
      id={id}
      className={classNames(
        skinsStyle.AfterScroll,
        animation && skinsStyle[animation],
        propsClassName,
      )}
      {...eventHandlers}
    >
      <div
        className={classNames(
          skinsStyle.screenWidthBackground,
          scrolled && skinsStyle.scrolled,
        )}
      ></div>
      <div>
        <div>{children}</div>
      </div>
    </SemanticElement>
  );
};

export default AfterScroll;
